// import Seo from "../components/seo"
import "../css/gutenberg.css"
import "moment/locale/de"
import "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import Container from "../components/container"
import Indent from "../components/indent"
import React, { lazy, Suspense, useState } from "react"
import ReactHtmlParser from 'react-html-parser';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Spacing from "../components/spacing"
import tw from "twin.macro"

const PersonsTemplate = ({
  data: { protagonists, seo },
}) => {

  protagonists = protagonists.edges.map(({ node }) => node)
  protagonists.sort((a, b) => a.title < b.title ? -1 : a.title > b.title ? 1 : 0)

  var sortOrder = ['Speaker*innen', 'Impulsgeber*innen', 'Panelist*innen', 'Moderator*innen']
  protagonists.sort(function(a, b){
    return sortOrder.indexOf(a.member.category) - sortOrder.indexOf(b.member.category)
  })

  const categories = Object.entries(groupBy(protagonists))

  function groupBy(objectArray)
  {
    return objectArray.reduce((acc, obj) => {
       const key = obj.member.category;
       if (!acc[key]) {
          acc[key] = [];
       }
       acc[key].push(obj);
       return acc;
    }, {});
  }

  return (
    <>
      <div>
        <div tw="grid grid-cols-1 md:grid-cols-2">
        </div>
        <section tw="" id="aktive">
          <Spacing space="extralarge" padding>
            <Container>
              <h1 tw="text-3xl font-bold lg:text-5xl">
                Mitwirkende & Speaker*innen
              </h1>
              <Indent>
                <p tw="pt-8 pb-8 text-xl">
                Mit über 80 Mitwirkenden steht der 2. Gleichstellungstag für geballte Expertise und vielfältige Perspektiven. Gemeinsam bereichern sie mit fundiertem Wissen und unterschiedlichen Blickwinkeln auf Gleichstellung und gesellschaftlichen Wandel die verschiedenen Formate – ob Workshop, Panel, Impulsvortrag oder Blitztalk. Unsere Speaker*innen, Moderator*innen und Impulsgeber*innen kommen aus Wissenschaft, Politik und Wirtschaft, aus lokalen Initiativen und international agierenden Netzwerken aus dem ganzen Bundesgebiet.
                </p>
              </Indent>
              <Indent>
                {categories.map((category, index) =>
                  <span tw="text-sm pt-4 pb-10">
                    <button tw="mb-4 p-1 pl-3 pr-3 border border-gray-900 mr-3" onClick={() => scrollTo(`#kat_${index}`)}>{category[0]}</button>
                  </span>
                )}
              </Indent>
              {categories.map((category, index) =>
                <Indent>
                  <p tw="font-bold text-4xl mb-5 pt-8 pb-10">
                    {category[0]}
                  </p>
                  <div tw="mb-10 grid grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-3" id={'kat_' + index}>
                  {category[1].map((element, indexCategory) =>
                    <div
                      key={element.id}
                      tw="flex flex-col"
                      css={[tw`block`]}
                    >
                      <Link to={element.uri} tw="mb-4">
                        <figure>
                          <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-1/1">
                            {element?.featuredImage && (
                              <GatsbyImage
                                image={
                                  element.featuredImage?.node?.localFile
                                    ?.childImageSharp?.gatsbyImageData
                                }
                                alt={element.title}
                                tw="object-contain aspect-ratio-item"
                                load="lazy"
                              />
                            )}
                          </div>
                          <figcaption className="person">
                            <p tw="text-base font-bold sm:text-xl md:text-xl lg:text-2xl">
                              {ReactHtmlParser(element.member.title)} {ReactHtmlParser(element.member.firstname)} {ReactHtmlParser(element.member.lastname)}
                            </p>
                            {element.member.jobtitle && (
                              <p tw="text-sm">{ReactHtmlParser(element.member.jobtitle)}</p>
                            )}
                          </figcaption>
                        </figure>
                      </Link>
                    </div>
                  )}
                </div>
                </Indent>
              )}
            </Container>
          </Spacing>
        </section>

      </div>
    </>
  )
}

/**export { Head } from "../components/head"**/

export default PersonsTemplate

export const pageQuery = graphql`
  query HomeById {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      seo {
        schema {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    seo: wpPage(id: { eq: "cG9zdDoxNzM0" }) {
      ...SeoFragment
    }
    page: wpPage(id: { eq: "cG9zdDoxNzM0" }) {
      id
      nodeType
      title
      uri
      content
      date(formatString: "MMMM DD, YYYY")
    ##  ...BlocksFragment
    }
    #members: allWpMember(sort: { fields: member___firstname, order: ASC }) {
    #  edges {
    #    node {
    #      id
    #      title
    #      member {
    #        email
    #        firstname
    #        jobtitle
    #        lastname
    #        phone
    #        title
    #      }
    #      content
    #      featuredImage {
    #        node {
    #          altText
    #          imageCopyright {
    #            photographer
    #            rights
    #          }
    #          localFile {
    #            childImageSharp {
    #              gatsbyImageData(
    #                quality: 60
    #                placeholder: BLURRED
    #                layout: FULL_WIDTH
    #                breakpoints: [320, 480, 640, 756, 920]
    #              )
    #            }
    #          }
    #        }
    #      }
    #      areas {
    #        nodes {
    #          id
    #          name
    #        }
    #      }
    #    }
    #  }
    #}

    protagonists: allWpProtagonist(
      sort: { fields: title }
    )
    {
      edges {
        node {
          id
          #title
          content
          slug
          uri
          member {
            category
            jobtitle
            title
            firstname
            lastname
          }
          featuredImage {
            node {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920]
                  )
                }
              }
            }
          }
        }
      }
    }

    events: allWpEvent(
      sort: { fields: startDate, order: ASC }
      limit: 2
    ) {
      edges {
        node {
          id
          title
          slug
          uri
          content
          excerpt
          allDay
          date
          duration
          endDate
          protagonists {
            moderation {
              ... on WpProtagonist {
                id
                #title
                member {
                  category
                  jobtitle
                  title
                  firstname
                  lastname
                }
                featuredImage {
                  node {
                    id
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 60
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                          breakpoints: [320, 480, 640, 756, 920]
                        )
                      }
                    }
                  }
                }
              }
            }
            together {
              ... on WpProtagonist {
                id
                #title
                member {
                  category
                  jobtitle
                  title
                  firstname
                  lastname
                }
                featuredImage {
                  node {
                    id
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 60
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                          breakpoints: [320, 480, 640, 756, 920]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          eventsCategories {
            nodes {
              name
            }
          }
          featured
          organizers {
            nodes {
              title
            }
          }
          venue {
            title
          }
          startDate
          #startDates
          status
        }
      }
    }
  }
`
